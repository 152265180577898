<template>
  <b-card
    :title="isEdit ? 'Update User' : 'Create User'"
    sub-title="Please complete the following fields with the user's details to create a user."
    sub-title-tag="p"
  >
    <hr class="mt-7" />
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="First name:"
              label-for="input-1"
            >
              <b-row>
                <b-form-input
                  id="input-1"
                  v-model="form.firstName"
                  type="text"
                  required
                  placeholder="Enter first name"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="Last name:"
              label-for="input-1"
            >
              <b-row>
                <b-form-input
                  id="input-1"
                  v-model="form.lastName"
                  type="text"
                  required
                  placeholder="Enter last name"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="Email address:"
              label-for="input-1"
            >
              <b-row>
                <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  required
                  disabled
                  readonly
                  placeholder="Enter email"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="Phone number:"
              label-for="input-1"
            >
              <b-row>
                <b-form-input
                  id="input-1"
                  v-model="form.phoneNumber"
                  type="tel"
                  required
                  placeholder="Enter phone number"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
        <!-- </b-col> -->
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="Account type:"
              label-for="input-1"
            >
              <b-row>
                <b-select
                  id="input-1"
                  v-model="form.accountType"
                  type="tel"
                  required
                  placeholder="Enter phone number"
                  :options="userAccountTypes"
                >
                </b-select>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
      </b-row>

      <div class="float-right">
        <b-button type="submit" variant="primary" ref="addUserButton"
          >Submit</b-button
        >
      </div>
    </b-form>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import {
  ADD_USER,
  GET_USER,
  UPDATE_USER_ACCOUNT,
} from "@/core/services/store/users.module";
import { accountTypes } from "../../../constants";
export default {
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      isEdit: false,
      userId: null,
      form: {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        accountType: null,
      },
      userAccountTypes: [
        {
          value: null,
          text: "Select account type",
          disabled: true,
        },
        ...accountTypes.map((account) => ({
          value: account.toLowerCase(),
          text: account,
        })),
      ],
    };
  },
  created() {
    this.isEdit = new String(this.$router.history.current.fullPath).includes(
      "edit"
    );
    this.userId = this.$router.history.current.params.id;
    if (this.currentUser.accountType === "system_admin") {
      this.userAccountTypes = [
        ...this.userAccountTypes,
        {
          value: "system_admin",
          text: "System Admin",
        },
      ];
    }
    if (this.isEdit) {
      this.getUser();
    }
  },
  methods: {
    onSubmit() {
      if (this.isSubmitting === true) return;
      this.isSubmitting = true;
      const addUserButton = this.$refs["addUserButton"];
      addUserButton.classList.add("spinner", "spinner-light", "spinner-right");

      if (this.isEdit) {
        this.$store
          .dispatch(UPDATE_USER_ACCOUNT, {
            ...this.form,
            userId: this.userId,
            requestBy: this.currentUser._id,
          })
          .then(() => {
            this.$bvToast.toast(`User successfully updated`, {
              title: `Updated`,
              variant: "success",
              solid: true,
              appendToast: true,
            });
          })
          .catch((err) => {
            this.$bvToast.toast(
              typeof err !== "undefined"
                ? err.message
                : "Failed to update user account.",
              {
                title: `Failed to update account`,
                variant: "danger",
                solid: true,
                appendToast: true,
              }
            );
          })
          .finally(() => {
            this.isSubmitting = false;
            addUserButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        this.$store
          .dispatch(ADD_USER, {
            ...this.form,
            userId: this.currentUser._id,
          })
          .then(() => {
            this.form = {
              email: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              accountType: null,
            };
            this.$bvToast.toast(`User successfully added`, {
              title: `Submitted`,
              variant: "success",
              solid: true,
              appendToast: true,
            });
          })
          .catch((err) => {
            this.$bvToast.toast(
              typeof err !== "undefined"
                ? err.message
                : "Failed to create user account.",
              {
                title: `Failed to create account`,
                variant: "danger",
                solid: true,
                appendToast: true,
              }
            );
          })
          .finally(() => {
            this.isSubmitting = false;
            addUserButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    getUser() {
      this.$store
        .dispatch(GET_USER, this.userId)
        .then((user) => {
          this.form = {
            email: user.email,
            firstName: user.name.firstName,
            lastName: user.name.lastName,
            phoneNumber: user.phoneNumber,
            accountType: user.accountType,
          };
        })
        .catch((err) => {
          this.$bvToast.toast(
            typeof err !== "undefined"
              ? err.message
              : "Failed to fetch user account. Please reload page to try again.",
            {
              title: `Failed to create account`,
              variant: "danger",
              solid: true,
              appendToast: true,
            }
          );
        })
        .finally(() => {});
      // }, 300);
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
